<template>
  <div class="py-2 px-3" :class="colorCard">
    <v-card
      outlined
      :elevation="1"
      class="rounded-lg"
      :color="statusCard.card"
      v-ripple="{ center: true }"
      height="120px"
    >
      <v-card-text class="py-2 d-flex flex-column px-1">
        <div class="">
          <div class="d-flex justify-center align-end py-3" style="gap:0.5em">
            <v-icon
              class="text--darken-4"
              :class="statusCard.visit"
            >mdi-text-box-check-outline</v-icon>
            <span
              class="text-h3 text--darken-4 font-weight-bold"
              :class="statusCard.visit"
              style="line-height: 1em;"
              v-text="details.visitas_totales"
            />
            <span class="mx-1">-</span>
            <span class="primary--text caption font-weight-bold">
              <v-icon
                color="teal"
                size="15"
                class="mt-n1"
              >mdi-account-tie-outline</v-icon>
              <template>
               {{ details.clientes_activos }}
              </template>
            </span>
            <!-- <span class="text-h6 blue-grey--text font-weight-medium">
              {{details.visitas_planificadas}}/{{ details.visitas_no_planificadas }}
            </span> -->
          </div>
            <v-row class="mx-0 mt-1">
              <v-col cols="6" class="px-1 py-0">
                <span class="primary--text caption font-weight-medium">
                  <v-icon
                    color="icono"
                    size="15"
                    class="mt-n1"
                  >mdi-clock-outline</v-icon>
                  <template v-if="details.hora_inicio_visita !== '00:00'">
                    {{ fecha + ' ' + details.hora_inicio_visita | hora }}
                  </template>
                  <template v-else>
                    00:00
                  </template>
                </span>
              </v-col>
              <v-col cols="6" class="px-1 py-0">
                <span class="primary--text caption font-weight-medium">
                  <v-icon
                    color="red"
                    size="15"
                    class="mt-n1"
                  >mdi-clock-check-outline</v-icon>
                  <template v-if="details.hora_fin_ultima_visita !== '00:00'">
                  {{ fecha + ' ' + details.hora_fin_ultima_visita | hora }}
                  </template>
                  <template v-else>
                    00:00
                  </template>
                </span>
              </v-col>
              <v-col cols="6" class="px-1 py-0">
                <span class="primary--text caption font-weight-bold">
                  <v-icon
                    color="primary"
                    size="15"
                    class="mt-n1"
                  >mdi-alarm-check</v-icon>
                  <template>
                   {{ details.total_horas_trabajadas }}h
                  </template>
                </span>
              </v-col>
              <v-col cols="6" class="px-1 py-0">
                <span class="primary--text caption font-weight-bold">
                  <v-icon
                    color="black"
                    size="15"
                    class="mt-n1"
                  >mdi-briefcase-clock-outline</v-icon>
                  <template>
                   {{ details.promedio_tiempo_visita_minutos }}m
                  </template>
                </span>
              </v-col>
              <!-- <v-col cols="6" class="px-1 py-0">
                <span class="primary--text caption font-weight-bold">
                  <v-icon
                    color="teal"
                    size="15"
                    class="mt-n1"
                  >mdi-account-tie-outline</v-icon>
                  <template>
                   {{ details.clientes_activos }}
                  </template>
                </span>
              </v-col> -->
            </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'VisitaCard',
  // components:{
  //   DetallesVisita: () => import(
  //     /* webpackChunkName: "detalles-visita" */
  //     './DetallesVisita.vue'
  //   ),
  //  },
  props:{
    details:{
      type: Object,
      default: () => ({})
    },
    fecha:{
      type: String,
      default: moment().format("YYYY-MM-DD")
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dist_metros: '',
      dist_status: {
        text: '',
        color: '',
      },
      showDetails: false,
      min: 20,
      media: 24
    }
  },
  filters: {
    hora(time) {
       return time != "null" ? moment(time).format('hh:mm a') : '';
    },
    truncFrecuencia(val) {
      return val.substr(0,1);
    }
  },
  computed: {
    visita_duracion () {
      if (this.details.visita.hora_inicio !== null && this.details.visita.hora_fin !== null) {
        const inicio = moment(`${this.fecha} ${this.details?.visita?.hora_inicio}`);
        const fin = moment(`${this.fecha} ${this.details?.visita?.hora_fin}`);
        const duracion = fin.diff(inicio)

        const hours = Math.floor(moment.duration(duracion).asHours()).toString()

        return `${ hours.length < 2 ? hours.padStart(2, '0') : hours }:${ moment.utc(duracion).format("mm:ss") }`
      }
      return null
    },
    colorCard(){
      return this.index % 2 === 0 ? 'light-blue-color' : 'transparent'
    },
    statusCard(){
      const CARD_COLOR = {
        0: 'red-indicator',
        1: 'warning-indicator',
        2: 'success-indicator',
      }
      const VISIT_COLOR = {
        0: 'red--text',
        1: 'orange--text',
        2: 'teal--text',
      }

      const STATUS = this.details?.visitas_totales < 20
        ? 0
        : this.details?.visitas_totales >= 20 && this.details?.visitas_totales < 24
        ? 1
        : 2

      return {
        card: CARD_COLOR[STATUS] ?? '',
        visit: VISIT_COLOR[STATUS] ?? '',
      }

    }
  },
  methods: {
    viewDetails(){
      if(this.details.visita)
        this.showDetails = true
    }
  },
  filters: {
    diaMes(date) {
      return moment(date).format("DD")
    },
    diaSemana(date) {
      return moment(date).format("dddd")
    },
    mes(date) {
      return moment(date).format("MMMM");
    },
    hora(time) {
       return time != "null" ? moment(time).format('hh:mm a') : '00:00';
    },
  }
}
</script>
<style>
.point-sale-remove{
  position: absolute !important;
  top: -15%;
  left: 42%;
  width: 2px;
  height: 130%;
  opacity: .8;
  background-color: #cfd8dc !important;
  transform: rotate(-40deg) translateX(50%);
}

.details-visit {
  cursor: pointer;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  overflow: hidden;
}

.details-visit:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  top: 0;
  background: #003b49 !important;
  width: 4px;
  -webkit-transform: translateX(-4px);
  transform: translateX(-4px);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.details-visit:hover:before, .details-visit:focus:before, .details-visit:active:before {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.success-indicator{
  background-color: #DBFAF2 !important;
  border-color: #DBFAF2 !important;
}

.warning-indicator{
  background-color: #FFF9C4 !important;
  border-color: #FFF9C4 !important;
}

.red-indicator{
  background-color: #FFEBEE !important;
  border-color: #FFEBEE !important;
}


</style>
